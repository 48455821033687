<template>
  <v-col cols="12">
    <div>
      <BaseHeadline size="4">
        {{ $t("SEMINAR.INFO_BAR.DURATION.TITLE") }}
      </BaseHeadline>
    </div>

    <div>
      <BaseText>
        {{ $tc("SEMINAR.INFO_BAR.DURATION.DAYS", duration, { count: duration }) }}
      </BaseText>
    </div>
  </v-col>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

export default {
  name: "SeminarDuration",

  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },

  computed: {
    duration() {
      const start = dayjs(this.seminar.startAt);
      const end = dayjs(this.seminar.endAt);
      const duration = dayjs.duration(end.diff(start));

      return Math.ceil(duration.asDays());
    },
  },

  created() {
    dayjs.extend(duration);
  },
};
</script>
