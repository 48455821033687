<template>
  <v-col cols="12" v-if="contactCount">
    <div class="mb-2">
      <BaseHeadline size="4">
        {{ $tc("SEMINAR.INFO_BAR.CONTACT_PEOPLE.TITLE", contactCount) }}
      </BaseHeadline>
    </div>

    <div>
      <div v-for="seminarContact of seminarContactPeople" :key="seminarContact.id">
        <SeminarContact
          :seminarContact="seminarContact"
          :teamContactEmail="seminar.seminarType.teamContactEmail"
        />
      </div>
    </div>
  </v-col>
</template>

<script>
import SeminarContact from "@components/SeminarInfoBar/SeminarContactPeople/partials/SeminarContact.vue";

export default {
  name: "SeminarContactPeople",

  components: {
    SeminarContact,
  },

  props: {
    seminar: {
      type: Object,
      required: true,
      default: () => ({
        seminarContactPeople: [],
      }),
    },
  },

  computed: {
    seminarContactPeople() {
      return this.seminar.seminarContactPeople;
    },

    contactCount() {
      if (!this.seminarContactPeople) return null;

      return this.seminarContactPeople.length;
    },
  },
};
</script>
