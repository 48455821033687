<template>
  <v-col cols="12">
    <BaseHeadline size="4">
      {{ seminarNumber }}
    </BaseHeadline>
  </v-col>
</template>

<script>
export default {
  name: "SeminarNumber",

  props: {
    seminarNumber: {
      type: String,
      required: true,
    },
  },
};
</script>
