<template>
  <v-row>
    <v-col cols="auto" align-self="center">
      <BaseAvatar :displayName="seminarContact.displayName" size="74" />
    </v-col>

    <v-col align-self="center">
      <BaseHeadline size="4">{{ seminarContact.displayName }}</BaseHeadline>

      <div v-if="teamContactEmail">
        <div class="d-flex">
          <div class="align-self-center mr-2">
            <v-icon small color="lowEmphasis">fal fa-envelope</v-icon>
          </div>
          <div class="align-self-center pt-1">
            <BaseText>
              {{ teamContactEmail }}
            </BaseText>
          </div>
        </div>
      </div>

      <div v-if="seminarContact.phone">
        <div class="d-flex">
          <div class="align-self-center mr-2">
            <v-icon small color="lowEmphasis">fal fa-phone</v-icon>
          </div>
          <div class="align-self-center pt-1">
            <BaseText>
              <BasePhoneLink :phoneNumber="seminarContact.phone">
                {{ seminarContact.phone }}
              </BasePhoneLink>
            </BaseText>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SeminarContact",

  props: {
    seminarContact: {
      type: Object,
      required: true,
    },

    teamContactEmail: {
      type: String,
      required: true,
    },
  },
};
</script>
