<template>
  <div>
    <BaseHeadline size="4" class="my-4">
      {{ defaultTitle }}
    </BaseHeadline>

    <BaseBorderSheet transparent class="py-4 px-6">
      <DocumentRow
        v-for="(document, index) of documents"
        :index="index"
        :key="document.id"
        :document="document"
        :length="documents.length"
        @addSelection="addDocumentSelection($event)"
        @removeSelection="removeDocumentSelection($event)"
        :multiSelect="multiSelect"
      />
    </BaseBorderSheet>

    <BaseButton
      v-if="hasSelection"
      @click="downloadDocuments"
      :disabled="!hasSelection"
      class="mt-4"
      small
      :loading="downloading"
    >
      {{ $t("SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.BTN_LABEL", { count: documentCount }) }}
    </BaseButton>
  </div>
</template>

<script>
import DocumentRow from "@components/AuthSeminar/SeminarDocuments/partials/DocumentRow.vue";
import { isEmpty } from "lodash";

export default {
  name: "SeminarDocuments",

  data() {
    return {
      selection: [],
    };
  },

  components: {
    DocumentRow,
  },

  props: {
    documents: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: false,
    },

    multiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },

    downloading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    addDocumentSelection(id) {
      this.selection.push(id);
    },

    removeDocumentSelection(id) {
      const newSelection = this.selection.filter((docId) => docId !== id);
      this.selection = newSelection;
    },

    downloadDocuments() {
      this.$emit("downloadDocuments", this.selection);
    },
  },

  computed: {
    defaultTitle() {
      return this.title
        ? this.$t(this.title)
        : this.$t("SEMINAR_MANAGEMENT.SEMINAR_DOCUMENTS.DEFAULT_TITLE");
    },

    hasSelection() {
      return !isEmpty(this.selection);
    },

    documentCount() {
      return this.selection.length;
    },

    showDownloadButton() {
      return this.multiSelect && this.hasSelection;
    },
  },
};
</script>
