<template>
  <v-row no-gutters>
    <v-col cols="auto" align-self="center" v-if="multiSelect" class="pr-4">
      <BaseCheckbox v-model="isSelected" class="mt-0" />
    </v-col>

    <v-col cols="auto" align-self="center" class="pr-4">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red" v-bind="attrs" v-on="on">{{ documentTypeIcon }}</v-icon>
        </template>
        <span>{{ document.fileExtension }}</span>
      </v-tooltip>
    </v-col>

    <v-col cols="auto" align-self="center" class="pr-4">
      <BaseText> {{ document.displayName }} </BaseText>
    </v-col>

    <v-col cols="auto" align-self="center" v-if="document.documentType.display_name">
      <BaseText body="2" color="gray600"> {{ document.documentType.display_name }} </BaseText>
    </v-col>

    <v-spacer />

    <v-col cols="auto" align-self="center" class="pr-4">
      <v-btn small icon @click="openLink">
        <v-icon small>far fa-eye</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="auto" align-self="center">
      <v-btn small icon @click="openDocument">
        <v-icon small>far fa-download</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12" class="my-3" v-if="showDivider">
      <v-divider />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DocumentRow",

  data() {
    return {
      isSelected: false,
    };
  },

  props: {
    document: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    length: {
      type: Number,
      required: true,
    },

    multiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  watch: {
    isSelected(value) {
      if (value) {
        this.$emit("addSelection", this.document.seminarDocumentDataId);
      } else {
        this.$emit("removeSelection", this.document.seminarDocumentDataId);
      }
    },
  },

  methods: {
    openDocument() {
      window.open(this.document.url);
    },

    openLink() {
      window.location = this.document.url;
    },
  },

  computed: {
    isOnly() {
      return this.index === 0;
    },

    isLast() {
      return this.index + 1 === this.length;
    },

    showDivider() {
      return !this.isOnly || !this.isLast;
    },

    documentTypeIcon() {
      switch (this.document.fileExtension) {
        case "doc":
          return "fas fa-file-word";

        case "docx":
          return "fas fa-file-word";

        case "jpg":
          return "fas fa-file-image";

        case "png":
          return "fas fa-file-image";

        case "zip":
          return "fas fa-file-archive";

        case "pdf":
          return "fas fa-file-pdf";

        default:
          return "fas fa-file-alt";
      }
    },
  },
};
</script>
