<template>
  <v-col cols="12">
    <div>
      <BaseHeadline size="4">
        {{ $t("SEMINAR.INFO_BAR.DATE.TITLE") }}
      </BaseHeadline>
    </div>
    <div>
      <BaseText itemprop="startDate">
        {{ $t("SEMINAR.INFO_BAR.DATE.BEGINNING", { dateTime: dateTime(seminar.startAt) }) }}
      </BaseText>
    </div>
    <div>
      <BaseText itemprop="endDate">
        {{ $t("SEMINAR.INFO_BAR.DATE.ENDING", { dateTime: dateTime(seminar.endAt) }) }}
      </BaseText>
    </div>
  </v-col>
</template>

<script>
import dateFilter from "@mixins/dateFilter";

export default {
  name: "SeminarDate",

  mixins: [dateFilter],

  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },
};
</script>
