<template>
  <div v-if="description">
    <div v-html="description"></div>
    <div @click="showMore = !showMore" class="clickable primary--text">{{ descriptionLabel }}</div>
  </div>
</template>

<script>
import stringShortenerHelper from "@helper/stringShortenerHelper";
export default {
  name: "SeminarDescription",

  data() {
    return {
      showMore: false,
    };
  },

  props: {
    seminarDescription: {
      type: String,
      required: true,
    },
  },

  computed: {
    description() {
      if (this.showMore) return this.seminarDescription;

      return stringShortenerHelper(this.seminarDescription);
    },

    descriptionLabel() {
      return !this.showMore
        ? this.$t("SEMINAR_MANAGEMENT.SEMINAR.SHOW.DESCRIPTION_LABEL_MORE")
        : this.$t("SEMINAR_MANAGEMENT.SEMINAR.SHOW.DESCRIPTION_LABEL_LESS");
    },
  },
};
</script>
