<template>
  <v-col cols="12">
    <div>
      <BaseHeadline size="4">
        {{ $t("SEMINAR.INFO_BAR.LOCATION") }}
      </BaseHeadline>
    </div>

    <BaseText itemtype="https://schema.org/location">
      <BaseSeminarLocation
        :seminarId="seminar.id"
        :seminarTypeId="seminar.seminarType.id"
        itemprop="name"
      />
    </BaseText>
  </v-col>
</template>

<script>
export default {
  name: "SeminarLocation",

  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },
};
</script>
